const Boxes = {
    box1: function () {
      var brd = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-5, 11.5, 11, -4.5],keepaspectratio: true, axis:false, ticks:false, grid:true, pan:{enabled:false}, zoom:{enabled:false}, showCopyright:false, showNavigation:false});
      brd.options.layer['line'] =6;
      brd.options.layer['segment'] =9;
      brd.options.layer['polygon'] =12;
      brd.options.layer['image'] =8;
      var resize = function () {
          brd.resizeContainer(brd.containerObj.clientWidth, brd.containerObj.clientHeight, true);
          brd.fullUpdate();
          };
      window.onresize = resize;
      var wts = [8050.00, 19500.00, 10500.00];
      brd.create('text', [3,11, '<b>Measuring Density of a Solid</b>'], {fixed:true, anchorX:'middle', fontSize:function(){return 32*brd.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	  brd.create('image', ['/assets/goldbars.svg',[-3.75, 9.75],[0.5, 0.5]],{anchorX:'right', anchorY:'middle'});
	  brd.create('image', ['/assets/silverbars.svg',[0.75, 9.75],[0.5, 0.5]],{anchorX:'right', anchorY:'middle'});
	  brd.create('image', ['/assets/ironbars.svg',[5.75, 9.75],[0.5, 0.5]],{anchorX:'right', anchorY:'middle'});
      brd.create('text', [-3,10, 'Gold = 19.5 g/cc'], {fixed:true, anchorX:'left', fontSize:function(){return 24*brd.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      brd.create('text', [1.5,10, 'Silver = 10.5 g/cc'], {fixed:true, anchorX:'left', fontSize:function(){return 24*brd.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      brd.create('text', [6.5,10, 'Steel = 8.050 g/cc'], {fixed:true, anchorX:'left', fontSize:function(){return 24*brd.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      var gs = wts[1];
      //brd.create('text', [4,-1, '1 g/cc'], {fixed:true, fontSize:function(){return 32*brd.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      //var df = brd.create('slider', [[4, -1],[5, -1],[1, 1, 2]], {visible:false, name: '', baseline:{visible:false, strokeWidth:7, strokeColor:'grey'},highline:{visible:false, strokeWidth:7, strokeColor:'black'}, size:8,face:'square', fillColor:'grey',strokeColor:'black', withTicks:false,label:{offset:[2,-15], fontSize:function(){return 22*brd.canvasHeight/800}}});
	  var df = 1;
      brd.create('text', [-4, -0.75, 'M<sub>app</sub>='], {fixed:true, fontSize:function(){return 28*brd.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      brd.create('text', [-4, 4, 'M<sub>vac</sub>='], {fixed:true, fontSize:function(){return 28*brd.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      var w1 = brd.create('input', [-2.75, -0.75, '', ''], {cssStyle:'width:10%;fontFamily:Oswald;background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return 24*brd.canvasHeight/800},fixed:true});

	  brd.create('text', [-1., -0.75, 'g'], {anchorX:'left', anchorY:'middle', fixed:true, fontSize:function(){return 24*brd.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

      var w2 = brd.create('input', [-2.75, 4.0, '', ''], {cssStyle:'width:10%;fontFamily:Oswald;background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return 24*brd.canvasHeight/800},fixed:true});

	  brd.create('text', [-1., 4, 'g'], {anchorX:'left', anchorY:'middle', fixed:true, fontSize:function(){return 24*brd.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

	  brd.create('text', [6., 4.5, 'Vacuum'], {anchorX:'middle', anchorY:'middle', fixed:true, fontSize:function(){return 24*brd.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

	  brd.create('text', [7., -0.75, 'Submerged in Water (&rho;_f = 1 g/cc)'], {anchorX:'middle', anchorY:'middle', fixed:true, fontSize:function(){return 24*brd.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

      brd.create('text', [10, 8, 'Density = <sup> &rho;_f M_{vac}</sup> &frasl; <sub>(M_{vac} - M_{app}) </sub>'], {anchorX:'right', fixed:true, fontSize:function(){return 24*brd.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

	  brd.create('text', [10, 7, function(){return ' = 1 x '+ w2.Value()+ '/('+ w2.Value() + '-' + w1.Value()+')'}], {visible:function(){if(w2.Value()==0 || w1.Value()==0){return false} else{return true}}, anchorX:'right', fixed:true, fontSize:function(){return 24*brd.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

      brd.create('text', [10, 6, function(){return '= ' + (df*w2.Value()/(w2.Value() - w1.Value())).toFixed(3) + ' g/cc'}], {visible:function(){if(w2.Value()==0 || w1.Value()==0){return false} else{return true}}, anchorX:'right',fixed:true, fontSize:function(){return 24*brd.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      brd.create('polygon', [[-8, -3.7], [-8, -14], [18, -14], [18, -3.7]],{vertices:{visible:false, fixed:true}, withLines:false, fillColor:'black'});
      brd.create('polygon', [[-8, 0], [-8, -14], [18, -14], [18, 0]],{vertices:{visible:false, fixed:true}, withLines:false, fillColor:'blue'});
      brd.create('polygon', [[-8, 0.75], [-8, 0], [18, 0], [18, 0.75]],{vertices:{visible:false, fixed:true}, withLines:false, fillColor:'black'});
	   brd.create('polygon', [[-8, 5.75], [-8, 5], [18, 5], [18, 5.75]],{vertices:{visible:false, fixed:true}, withLines:false, fillColor:'black'});
      var li = brd.create('segment', [[-1.35, -2.05],[1.35, -2.05]],{visible:true, fixed:true});
      var ri = brd.create('segment', [[-1.35,  2.45],[1.35, 2.45]],{visible:true, fixed:true});
      //var ball =brd.create('point', [0, x0], {name:'', size:18, face:'square', strokeColor:'black', strokeWidth:3, shadow:false, fillColor:'orange'});
	  //var group =[];
	  var i=0;
	  var ball = brd.create('image', ['/assets/kettlebell.svg', [4.5, -3.75],[1.5,1.5]],{attractors:[li,ri], attractorDistance:0.5, snatchDistance: 1});
      //brd.create('point', [0, -2.875], {name:'', size:18, face:'circle', strokeColor:'black', strokeWidth:3, shadow:false, fillColor:'black'});
      //
      //brd.create('point', [6, -2.875], {name:'', size:18, face:'circle', strokeColor:'black', strokeWidth:3, shadow:false, fillColor:'black'});
//
      brd.create('text', [-1.25,-2.9, function(){if(ball.Y() == -2.05 & ball.X()<=1.35){return Math.max(0.0, (1000*(1-df/(gs/1000+0.00))).toFixed(1))}
      else{return (0.00).toFixed(4)}}], {color:'black', fixed:true,  fontSize:function(){return 13*brd.canvasHeight/800}});

      brd.create('text', [-1.25, 1.6, function(){if(ball.Y() == 2.45 & ball.X() <=1.35){return (1000).toFixed(1)}
      else{return (0.00).toFixed(4)}}], {color:'black', fixed:true, fontSize:function(){return 13*brd.canvasHeight/800}});
      //
      brd.create('image', ['/assets/weighing.svg', [-1.75, -4.5],[3.5,3.5]],{fixed:true, shadow:false});
      brd.create('image', ['/assets/weighing.svg', [-1.75,  0],[3.5,3.5]],{fixed:true, shadow:false});
	  var shuffle= brd.create('image', ['/assets/shuffle.svg', [-2,  6],[1.5,1.5]],{fixed:true, shadow:false});
	  shuffle.setLabel('Click to Shuffle')
	  shuffle.label.setAttribute({anchorX:'middle',visible:false, offset:[10, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
      shuffle.on('over', function () {this.label.setAttribute({visible:true});});
      shuffle.on('out', function () {this.label.setAttribute({visible:false});});
	  var iret = function(){
		  if(i<2){return i=Math.min(2, i+1)}else{return i=0};
	  }
	  shuffle.on('down', function(){ball.moveTo([4.5, -3.75]); iret(); gs=wts[i];} )
}
}
export default Boxes;

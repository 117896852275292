<template>
  <div>
    <h3 ref="def">
      What is Buoyancy?
    </h3>
    <p>
      When an object is immersed in a fluid, the fluid exerts an upward force on the object. This force is known as buoyant force, and this phenomenon is known as buoyancy. The magnitude of the bouyant force acting on an object depends on the density of the fluid and the volume of fluid displaced by the object.
    </p>
    <h3>
      Buoyancy &  Apparent Weight
    </h3>
    <p>
      Have you ever wondered why objects feel lighter when they are submerged in water? The object feels lighter due to effect of buoyancy. Let us explain.
      There are two force acting on an object when it is submerged in a liquid: One is the force due to gravity (\(F_G\)) and the other is the force due to buoyancy (\(F_B\)). These two forces act in
      opposite direction. As a result the force due to gravity (that causes weight) can be partially or completely cancelled by the force due to buoyancy. This is why objects weigh less when submersed in a liquid.
    </p>
    <h3 ref="cal">
      Calculating Apparent Weight
    </h3>
    <p>
      Let's consider a solid object with volume V and made of a material with density \(\rho_s\). The object is submersed in a liquid with density \(\rho_f\).
      The force acting on this object due to gravity is given as:
      $$F_G = \rho_s g V$$
      where \(\rho_s\) is the density of the solid object, \(g \) is the acceleration due to gravity and \(V \) is the volume of the object. Note that the force due to gravity acts downward.
      We will use Archimedes principle to calculate the magnitude of buoyant force acting on this object. According to Archimedes principle, the magnitude of
      the buoyant force is equal to the weight of the displaced liquid. Thus, we obtain:
      $$F_B = \rho_f g V$$
      Note that the buoyant force acts upward.
      Now, we calculate the net downward force acting on the object.
      $$F_G - F_B = (\rho_s -\rho_f)Vg$$
      You can see that the net downward force action on the object is reduced due to buoyant force. This downward
    </p>
    <h3 ref="arche">
      The Archimedes Principle
    </h3>
    <p>
      The Archimedes principle states that the buoyant force exerted on an object immersed in a fluid is equal to the weight of the fluid displaced by the object. Stated in simple terms &mdash; the buoyant force acting on an object can be calculated by assuming the submerged portion of the object is made of the fluid that the object is submerged in and then by calculating the weight of that mass of fluid. Expressed as an equation, the Archimedes principles reads as
      $$F_\text{Buo} = \rho_f g V_\text{Sub}$$
      where \(V_\text{sub}\) is the volume of the object submerged in the fluid.
      For an object of volume \(V\) that is completely submerged in a fluid, the Archimedes principle becomes
      $$F_\text{Buo} = \rho_f g V$$
      The Archimedes principle is valid for any fluid whether its a liquid (such as water) or a gas (such as air).
    </p>
    <h3 ref="pg">
      Measuring Buoyancy
    </h3>
    <p> Have you ever noticed that an object feels lighter when it is submerged in water (or as a matter of fact in any fluid). This is a consequence of buoyancy. The weight of the object acts downward while the buoyant force due to the fluid acts upward. Thus, the weight of the object is opposed by the buoyant force exerted on it by the fluid &mdash; as a result the object feels lighter in water. By measuring the weight of the object inside and outside of the fluid, the buoyant force acting on the object can be measured. Through this interactive session, you will learn about &mdash; </p>
    <ul>
      <li>
        <h3>Measuring Buoyancy </h3>
        One of the ways to measure the buoyant force exerted on an object by a fluid is by determining the weight of the object inside and outside of the fluid. Let's say the object weighs \(W_{app}\) when measured while submerged in the fluid and weighs \(W_{vac}\) when measured in vaccuum.
        Then, the buoyant force acting on the object can be determined as
        $$F_\text{Buo} = W_{vac} - W_{app} $$
        The weight of an object in vacuum is simply its mass multiplied with gravity, i.e. \(W_{vac} = mg\). Further, the mass of a solid object equals density times volume i.e. \(m = \rho_s V\).
        Thus, we have
        $$F_\text{Buo} = \rho_s V g - W_{app}$$
      </li>
      <li>
        <h3>Factors Affecting Buoyancy </h3>
        The Archimedes principle states that the buoyant force acting on an object is equal to the weight of the fluid displaced by the object. Mathematically, this is expressed as
        $$F_\text{Buo} = \rho_f Vg$$
        where \(\rho_f\) is the density of the fluid, \(V\) is the volume of displaced fluid, and \(g\) is the acceleration due to gravity (a constant).  Thus, according to Archimedes principle:
        1.the greater the density of the fluid, the greater the buoyant force exerted by it on a submerged object.
        2. the larger the volume of fluid displaced, the greater the magnitude of the buoyant force.
      </li>
      <li>
        <h3> Application of Archimedes Principle: Determining the Density of Solid Objects </h3>
        The Archimedes principle can be used to determine the density of a solid object.
        Let's say the weight of an object when submerged in a fluid of known density is \(W_{in}\). The same object when measured outside of the fluid weights \(W_{vac} = \rho_s V g\) where \(\rho_s\) is the density of the solid body and \(V\) is its volume.
        Thus, the density of the solid object is
        $$ \rho_s = \frac{W_{vac}}{Vg}$$
        Due to buoyancy, the object weighs lesser when submerged in water. The weight of an object when submerged in a liquid is called its apparent weight. \(W_{app} \lt W_{vac}\).
        $$F_{Buo} = W_{vac} - W_{app} $$
        Using Archimedes principle, \(F_{Buo}\) can be expressed as
        $$F_{Buo} = \rho_f V g$$
        Substituting this in the above equation:
        $$\rho_f Vg  = W_{vac} - W_{app}$$
        or
        $$V  = \frac{W_{vac} - W_{app}}{\rho_f g}$$
        $$ \boxed{\rho_s = \frac{\rho_f W_{vac}}{W_{vac} - W_{app}}}$$
        The above formula is known as Archimedes formula. Archimedes formula can also be expressed in terms of vacuum mass (\(M_{vac}\)) and apparent mass (\(M_{app}\)) of the object. Note that \(W_{vac} = M_{vac} g\) and
        \(W_{app} = M_{app} g\). Substituting in the Archimedes formula, we obtain:
        $$ \boxed{\rho_s = \frac{\rho_f M_{vac}}{M_{vac} - M_{app}}}$$
      </li>
    </ul>
    <h3>MagicGraph | COVID-19 Metal Detector </h3>
    <p />
    <h5> Problem Statement </h5>
    <p>
      Kettlebells are out of stock this year &mdash; courtesy of COVID-19.
      You are a fitness enthusiast and you really need a kettlebell for your daily exercise.
      One day a mysterious salesman shows up to your door. He has several expensive kettlebells to sell &mdash; each made of a different metal.
      Your job is to figure out what metal the kettlebell is made of, and if it is worth the asking price.
    </p>
    <h5> Steps </h5>
    <p>
      Follow the steps below to accomplish this task.
      <ul class="a">
        <li> First, weight the kettlebell in vacuum. Enter the vacuum mass of the object in the input field labeled \(M_{vac}\).</li>
        <li> Next, measure the kettlebell in water. Enter the apparent mass (i.e. submerged mass) of the object in the input field labeled \(M_{app}\). </li>
        <li>
          Then, use Archimedes principle to calculate the density using \(M_{vac}\) and \(M_{app}\).
          Compare the calculated density with the densities of known metals to identify the metal that the kettlebell is made of.
        </li>
        <li> Click on the shuffle button to try different kettlebells. </li>
      </ul>
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Resistors',
  created: function () {
    // Store mutations
    this.$store.commit('navigation/resetState');
    let title = 'Buoyancy';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Buoyancy', img:'assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Origin of Buoyancy', img:'assets/number-2.svg', action: () => this.goto('ca')},
      {title: 'Archemedes Principle',img:'assets/number-3.svg', action: () => this.goto('ap')},
      {title: 'Virtual PlayGround',img:'assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule); 
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
